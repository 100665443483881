<template>
  <div>
    <master-component :config="config"></master-component>
  </div>
</template>

<script>
import { UserRole, InputType, DialogSize } from "./../js/constants";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import { InputRelate } from "../js/custom";
import { getLawyers } from "../js/dropdown-service";

export default {
  data() {
    return {};
  },
  created() {
    const config = this.config;
  },
  computed: {
    config() {
      return {
        name: "master-court",
        shortName: "ศาล",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    urlSection() {
      let searchUrl = "/court/search";
      let getUrl = "/court";
      let addUrl = "/court";
      let editUrl = "/court";
      let removeUrl = "/court";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "name",
              text: "ชื่อศาล",
              type: InputType.text,
              columnClass: "col-md-3",
            },
            {
              name: "lawyer",
              text: "ทนาย",
              type: InputType.dropdown,
              data: {
                promise: getLawyers(),
              },
              columnClass: "col-md-4",
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "courtType",
              text: "ประเภทศาล",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/courtTypes",
              },
            },
            Object.assign({}, InputRelate.getProvinceRelate()),
            {
              name: "area",
              text: "Area",
              type: InputType.dropdownRelate,
              data: {
                url: `/dropdown/court/areas?provinceId=`,
              },
              parent: "province",
              parentField: "provinceId",
              rule: {},
              columnClass: "col-md-6",
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MANAGEMENT],
        edit: [UserRole.ADMIN, UserRole.MANAGEMENT],
        remove: [UserRole.ADMIN],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "ชื่อศาล",
          },
          {
            name: "courtType.text",
            text: "ประเภทศาล",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            Object.assign(
              {},
              InputRelate.getProvinceRelate(),
              {
                rule: { required: true },
              },
              {
                disabledIf: (context) => {
                  if (context?.model?.id) {
                    return true;
                  }

                  return false;
                },
              }
            ),
            {
              name: "courtType",
              text: "ประเภทศาล",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/courtTypes",
              },
              rule: {
                required: true,
              },
            },
            {
              name: "lawsuitFee",
              text: "ค่าดำเนินการ",
              type: InputType.number,
              decimal: 2,
              rule: {
                // number: true,
                required: true,
              },
            },
          ],
          [
            {
              name: "lawyarManagementAmount",
              text: "ค่าบริหารทนาย",
              type: InputType.number,
              decimal: 2,
              rule: {
                // number: true,
              },
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [{ type: InputType.section, text: "ค่าวิชาชีพทนาย" }],
          [
            {
              name: "customerValues",
              default: [],
              type: InputType.courtCustomer,
            },
          ],
          [{ type: InputType.section, text: "Lawyers" }],
          [
            {
              name: "lawyers",
              default: [],
              type: InputType.tableLawyerCourt,
            },
          ],
          [{ type: InputType.section, text: "Covarage Area" }],
          [
            {
              name: "areas",
              default: [],
              type: InputType.courtCovarageArea,
            },
          ],
        ],
      };
    },
  },
};
</script>
